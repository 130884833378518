<template>
    <div>
        <b-table striped hover :items="jurisdictions" :fields="columns" show-empty>
            <template #empty>
                <h6 class="text-center">No hay juridicciones agregadas</h6>
            </template>
            <template #cell(actions)="data">
                <div>
                    <b-button @click="removeRow(data.index)" variant="danger">
                        <b-icon icon="x-circle" />
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "list-juridictions",
    props: {
        jurisdictions: {
            type: Array,
        },
        noColumnNit: {
            type: Boolean,
            require: false
        },
    },
    computed: {
        columns() {
            return this.noColumnNit ? this.columnsWithoutNit : this.columnsWithNit;
        }
    },
    data() {
        return {
            columnsWithoutNit: [
                { key: "jurisdiction", label: "Juridicción" },
                { key: "actions", label: "", class: "text-center" },
            ],
            columnsWithNit: [
                { key: "jurisdiction", label: "Juridicción" },
                { key: "nit", label: "Número de Identificacíon Tributaria" },
                { key: "actions", label: "", class: "text-center" },
            ],
        };
    },
    watch: {
        listJuridictions() {
            this.setjuridictions(this.jurisdictions);
        },
    },
    methods: {
        ...mapActions("legalEntity", ["setjuridictions"]),
        removeRow(index) {
            this.$emit("delete", index);
        },
    },
};
</script>

<style>
.actions-column {
    min-width: 135px;
}
</style>