<template>
    <div>
        <p class="page-title" style="font-size: small"><strong style="color: red">**IMPORTANTE:**</strong> Para editar un Beneficiario Final de la tabla debe seleccionar el tipo de Beneficiario Final que va a editar y darle clic al botón verde con el icono del lápiz. El botón se habilita una vez le da clic al tipo de Beneficiario Final correcto.</p>
        <b-table
            striped
            hover
            :items="items"
            :fields="columns"
            show-empty
            responsive
        >
            <template #empty>
                <h6 class="text-center">No hay beneficiarios finales</h6>
            </template>
            <template #cell(actions)="data">
                <div>
                    <b-button @click="editRow(data.index)" variant="success">
                        <b-icon icon="pencil" v-b-tooltip.hover title="Editar solo funciona para BF del mismo tipo" />
                    </b-button>
                    <b-button @click="removeRow(data.index)" variant="danger">
                         <b-icon icon="x-circle" />
                    </b-button>
                </div>
            </template>
            <template #cell(reason)="data">
                <b-icon-card-list
                    style="cursor: pointer"
                    v-b-tooltip.hover.left
                    variant="primary"
                    :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
                />
            </template>
        </b-table>
    </div>
</template>

<script>
import { reasons } from "@/config/final-beneficiary-reasons";

export default {
    name: "complete-final-beneficiaries",
    props: {
        finalBeneficiaries: {
            type: Array,
            required: true,
        },
        finalBeneficiaryWho: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            columns: [
                { key: "name", label: "Primer Nombre" },
                { key: "secondName", label: "Segundo Nombre" },
                { key: "surnames", label: "Primer Apellido" },
                { key: "secondSurname", label: "Segundo Apellido" },
                { key: "cedula", label: "Cédula" },
                { key: "birthDate", label: "Fecha de nacimiento" },
                { key: "nationality", label: "Nacionalidad" },
                { key: "direction", label: "Dirección" },
                { key: "statusAcquireDate", label: "Fecha de adquisición" },
                { key: "email", label: "Correo electrónico" },
                { key: "phone", label: "Teléfono" },
                { key: "reason", label: "Motivo", class: "text-center" },
                { key: "actions", label: "", tdClass: "actions-column" },
            ],
            items: [],
        };
    },
    watch: {
        finalBeneficiaries() {
            this.items = this.finalBeneficiaries.filter(item => item.finalBeneficiaryWho === this.finalBeneficiaryWho);
        },
    },
    methods: {
        editRow(i) {
            let index = this.finalBeneficiaries.findIndex(x => x === this.items[i]);
            let finalBeneficiary = this.finalBeneficiaries[index];
            this.$emit("update", { index, finalBeneficiary });
        },
        removeRow(i) {
            let index = this.finalBeneficiaries.findIndex(x => x === this.items[i]);
            const uniqueIndex = Date.now();
            this.$emit("delete", { index, uniqueIndex });
        },
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        }
    },
};
</script>

<style>
.actions-column {
    min-width: 135px;
}
</style>