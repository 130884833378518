<template>
    <v-select
        v-model="activity"
        :options="activities"
        :clearable="false"
        @input="changed"
    >
        <template #selected-option="option">
            {{ option.label }}
        </template>
        <template #option="option">
            {{ option.label }}
        </template>
    </v-select>
</template>

<script>
import { activities } from "@/config/activities";

export default {
    name: "principal-activity-select",
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            activity: null,
            activities: activities,
        };
    },
    mounted() {
        this.activity = this.value;
    },
    watch: {
        value() {
            this.activity = this.value;
        },
    },
    methods: {
        changed() {
            if (this.activity !== null) {
                this.$emit("input", this.activity);
            } else {
                this.$emit("input", null);
            }
        },
    },
};
</script>