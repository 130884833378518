export const prefixes = [
  {"AFGANISTAN": "+93"},
  {"ALBANIA": "+355"},
  {"ALEMANIA": "+49"},
  {"ANDORRA": "+376"},
  {"ANGOLA": "+244"},
  {"ANGUILLA": "+1-264"},
  {"ANTÁRTIDA": "+672"},
  {"ANTIGUA Y BARBUDA": "+1-268"},
  {"ANTILLAS HOLANDESAS": "+599"},
  {"ARABIA SAUDITA": "+966"},
  {"ARGELIA": "+213"},
  {"ARGENTINA": "+54"},
  {"ARMENIA": "+374"},
  {"ARUBA": "+297"},
  {"AUSTRALIA": "+61"},
  {"AUSTRIA": "+43"},
  {"AZERBAIYAN": "+994"},
  {"BAHAMAS": "+1-242"},
  {"BANGLADESH": "+880"},
  {"BARBADOS": "+1-246"},
  {"BAREIN": "+973"},
  {"BELGICA": "+32"},
  {"BELICE": "+501"},
  {"BENIN": "+229"},
  {"BERMUDA": "+1-441"},
  {"BIELORRUSIA": "+375"},
  {"BIRMANIA": "+95"},
  {"BOLIVIA": "+591"},
  {"BOSNIA Y HERZEGOVINA": "+387"},
  {"BOTSUANA": "+267"},
  {"BRASIL": "+55"},
  {"BRUNEI": "+673"},
  {"BULGARIA": "+359"},
  {"BURKINA FASO": "+226"},
  {"BURUNDI": "+257"},
  {"BURMA": "+95"},
  {"BUTAN": "+975"},
  {"CABO VERDE": "+238"},
  {"CAMBODIA": "+855"},
  {"CAMERUN": "+237"},
  {"CANADA": "+1"},
  {"CATAR": "+974"},
  {"CHAD": "+235"},
  {"CHILE": "+56"},
  {"CHINA": "+86"},
  {"CHIPRE": "+357"},
  {"CIUDAD DEL VATICANO": "+379"},
  {"COLOMBIA": "+57"},
  {"COMORAS": "+269"},
  {"CONGO": "+242"},
  {"COREA DEL NORTE": "+850"},
  {"COREA DEL SUR": "+82"},
  {"COSTA DE MARFIL": "+225"},
  {"COSTA RICA": "+506"},
  {"CROACIA": "+385"},
  {"CUBA": "+53"},
  {"CURAZAO": "+599"},
  {"DINAMARCA": "+45"},
  {"DJIBOUTI": "+253"},
  {"DOMINICA": "+1-767"},
  {"ECUADOR": "+593"},
  {"EGIPTO": "+20"},
  {"EL SALVADOR": "+503"},
  {"EMIRATOS ARABES UNIDOS": "+971"},
  {"ERITREA": "+291"},
  {"REPUBLICA ESLOVACA": "+421"},
  {"ESLOVENIA": "+386"},
  {"ESPAÑA": "+34"},
  {"ESTADOS UNIDOS DE AMERICA": "+1"},
  {"ESTONIA": "+372"},
  {"ESUATINI": "+268"},
  {"ETIOPIA": "+251"},
  {"FILIPINAS": "+63"},
  {"FINLANDIA": "+358"},
  {"FIYI": "+679"},
  {"FRANCIA": "+33"},
  {"GABON": "+241"},
  {"GAMBIA": "+220"},
  {"GEORGIA": "+995"},
  {"GHANA": "+233"},
  {"GIBRALTAR": "+350"},
  {"GRANADA": "+1-473"},
  {"GRECIA": "+30"},
  {"GROENLANDIA": "+299"},
  {"GUAM": "+1-671"},
  {"GUATEMALA": "+502"},
  {"GUERNSEY": "+44-1481"},
  {"GUYANA": "+592"},
  {"GUINEA": "+224"},
  {"GUINEA-BISAU": "+245"},
  {"GUINEA ECUATORIAL": "+240"},
  {"HAITI": "+509"},
  {"HONDURAS": "+504"},
  {"HONG KONG": "+852"},
  {"HUNGRIA": "+36"},
  {"INDIA": "+91"},
  {"INDONESIA": "+62"},
  {"IRAK": "+964"},
  {"IRAN": "+98"},
  {"IRLANDA": "+353"},
  {"ISLANDIA": "+354"},
  {"ISLAS CAIMAN": "+1-345"},
  {"ISLAS COOK": "+682"},
  {"ISLAS DE ALAND": "+358"},
  {"ISLA MAN": "+44-1624"},
  {"ISLAS MARSHALL": "+692"},
  {"ISLAS PITCAIRN": "+870"},
  {"ISLAS SALOMON": "+677"},
  {"ISLAS TURCAS Y CAICOS": "+1-649"},
  {"ISLAS VIRGENES BRITANICAS": "+1-284"},
  {"ISRAEL": "+972"},
  {"ITALIA": "+39"},
  {"JAMAICA": "+1-876"},
  {"JAPON": "+81"},
  {"ISLA JERSEY": "+44-1534"},
  {"JORDANIA": "+962"},
  {"KAZAJISTAN": "+7"},
  {"KENIA": "+254"},
  {"KIRGUISTAN": "+996"},
  {"KIRIBATI": "+686"},
  {"KUWAIT": "+965"},
  {"KOSOVO": "+383"},
  {"LAOS (REPÚBLICA DEMOCRÁTICA)": "+856"},
  {"LATVIA": "+371"},
  {"LESOTO": "+266"},
  {"LETONIA": "+371"},
  {"LIBANO": "+961"},
  {"LIBERIA": "+231"},
  {"LIBIA": "+218"},
  {"LIECHTENSTEIN": "+423"},
  {"LITUANIA": "+370"},
  {"LUXEMBURGO": "+352"},
  {"MACAU": "+853"},
  {"MACEDONIA": "+389"},
  {"MADAGASCAR": "+261"},
  {"MALASIA": "+60"},
  {"MALAUI": "+265"},
  {"MALDIVAS": "+960"},
  {"MALI": "+223"},
  {"MALTA": "+356"},
  {"MARRUECOS": "+212"},
  {"MAURICIO": "+230"},
  {"MAURITANIA": "+222"},
  {"MEXICO": "+52"},
  {"MICRONESIA": "+691"},
  {"MOLDAVIA": "+373"},
  {"MONACO": "+377"},
  {"MONGOLIA": "+976"},
  {"MONTENEGRO": "+382"},
  {"MOZAMBIQUE": "+258"},
  {"MYAMAR": "+95"},
  {"NAMIBIA": "+264"},
  {"NAURU": "+674"},
  {"NEPAL": "+977"},
  {"NIEVES": "+1-869"},
  {"NICARAGUA": "+505"},
  {"NIGER": "+227"},
  {"NIGERIA": "+234"},
  {"NORUEGA": "+47"},
  {"NUEVA ZELANDA": "+64"},
  {"OMAN": "+968"},
  {"PAISES BAJOS": "+31"},
  {"PAKISTAN": "+92"},
  {"PALAOS": "+680"},
  {"PALESTINA": "+970"},
  {"PANAMA": "+507"},
  {"PAPUA NUEVA GUINEA": "+675"},
  {"PARAGUAY": "+595"},
  {"PERU": "+51"},
  {"POLINESIA FRANCESA": "+689"},
  {"POLONIA": "+48"},
  {"PORTUGAL": "+351"},
  {"PUERTO RICO": "+1-787, +1-939"},
  {"QATAR": "+974"},
  {"REINO UNIDO": "+44"},
  {"REPUBLICA CENTROAFRICANA": "+236"},
  {"REPUBLICA CHECA": "+420"},
  {"REPUBLICA DEL CONGO": "+242"},
  {"REPUBLICA DEMOCRÁTICA DEL CONGO": "+243"},
  {"REPUBLICA DOMINICANA": "+1-809, +1-829, +1-849"},
  {"SUDAFRICA": "+27"},
  {"RUANDA": "+250"},
  {"RUMANIA": "+40"},
  {"RUSIA": "+7"},
  {"SAMOA OCCIDENTAL": "+685"},
  {"SAMOA AMERICANA": "+1-684"},
  {"SAN BARTOLOME": "+590"},
  {"SAN CRISTOBAL Y NIEVES": "+1-869"},
  {"SAN MARINO": "+378"},
  {"SAN MARTIN": "+590"},
  {"SAN VICENTE Y LAS GRANADINAS": "+1-784"},
  {"SANTA LUCIA": "+1-758"},
  {"SANTO TOME Y PRINCIPE": "+239"},
  {"SENEGAL": "+221"},
  {"SERBIA": "+381"},
  {"SEYCHELLES": "+248"},
  {"SIERRA LEONA": "+232"},
  {"SINGAPUR": "+65"},
  {"SIRIA": "+963"},
  {"SOMALIA": "+252"},
  {"SRI LANKA": "+94"},
  {"SUDAN": "+249"},
  {"SUDAN DEL SUR": "+211"},
  {"SUECIA": "+46"},
  {"SUIZA": "+41"},
  {"SURINAM": "+597"},
  {"TAILANDIA": "+66"},
  {"TAIWAN": "+886"},
  {"TANZANIA": "+255"},
  {"TAYIKISTAN": "+992"},
  {"TIMOR ORIENTAL": "+670"},
  {"TOGO": "+228"},
  {"TONGA": "+676"},
  {"TRINIDAD Y TOBAGO": "+1-868"},
  {"TUNEZ": "+216"},
  {"TURKMENISTAN": "+993"},
  {"TURQUIA": "+90"},
  {"TUVALU": "+688"},
  {"UCRANIA": "+380"},
  {"UGANDA": "+256"},
  {"URUGUAY": "+598"},
  {"UZBEKISTAN": "+998"},
  {"VANUATU": "+678"},
  {"VENEZUELA": "+58"},
  {"VIETNAM": "+84"},
  {"YEMEN": "+967"},
  {"YIBUTI": "+253"},
  {"ZAMBIA": "+260"},
  {"ZIMBABWE": "+263"}
  ]