<template>
    <div>
        <b-form-group class="mt-3">
            <label class="form-title">
                Motivo principal por el cual se le otorga la condición de
                beneficiario final*
            </label>
            <select
                class="form-select form-select-sm rounded-0"
                v-model="reason"
                @change="change"
            >
                <option :value="null"></option>
                <option
                    v-for="reason in reasons"
                    :key="reason.key"
                    :value="reason.key"
                >
                    {{ reason.label }}
                </option>
                <option :value="-1">Otros ...</option>
            </select>
        </b-form-group>

        <b-form-group v-if="isOther">
            <label class="form-title"> Otros: </label>
            <b-input-group>
                <b-form-input
                    v-model="others"
                    size="sm"
                    class="rounded-0"
                    :debounce="200"
                />
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
import { reasons } from "@/config/final-beneficiary-reasons";

export default {
    name: "reason-get-final-beneficiary-status-select",
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            reason: null,
            others: null,

            reasons: reasons,
        };
    },
    mounted() {
        if (this.value !== null) {
            this.reason = this.value.status;
            this.others = this.value.others;
        }
    },
    computed: {
        isOther() {
            return this.reason === -1;
        },
    },
    watch: {
        others() {
            this.change();
        },
    },
    methods: {
        change() {
            if (!this.isOther) {
                this.others = "";
            }
            this.$emit("input", {
                status: this.reason,
                others: this.others,
            });
        },
        clear() {
            this.reason = null;
            this.others = "";
        },
        load(reason, others) {
            this.reason = reason;
            this.others = others;
        },
    },
};
</script>