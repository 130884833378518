<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2">REGISTRO NUEVO</h6>
        
        <h6 class="page-title">Seleccione un tipo de entidad o clasificación de la persona jurídica que desea registrar:</h6>

        <b-row class="mt-3">
            <b-col v-for="item in options" :key="item.value" class="card-option mb-3" cols="2">
                <b-card no-header no-body class="shadow-sm card-tab text-center"
                    :class="[{ active: item.value == selected }, item.class]" :id="item.value" @click="setActive($event)">
                    <label class="card-tab-title cs-pointer mx-2">
                        {{ item.text }}
                    </label>
                </b-card>
            </b-col>
            <hr>
        </b-row>

        <h6 class="page-title p-2">Ingrese el tipo de persona jurídica y número de ficha o folio que desea registrar:</h6>

        <!--Types-->
        <div ref="formFinalBeneficiaries">
            <LegalPersonOperationInPanamaType
                v-if="selected == 'legal-person-operation'"
                :payloadFinalBeneficiary="payloadFinalBeneficiary"
                :indexFinalBeneficiary="indexFinalBeneficiary"
                :tabIndex="tabIndex"
                @tabSelection="tabSelection"
            />
            <LegalPersonOperationType
                :key="selected"
                v-if="selected == 'legal-person-no-operation' || selected == 'legal-person-mix-operation'"
                :allowListedStockExchange="selected == 'legal-person-operation' || selected == 'legal-person-no-operation'"
                :showNoAssets="selected == 'legal-person-operation'"
                :payloadFinalBeneficiary="payloadFinalBeneficiary"
                :indexFinalBeneficiary="indexFinalBeneficiary"
                :tabIndex="tabIndex"
                @tabSelection="tabSelection"
            />
            <ListedLegalType
                v-if="selected == 'listed'"
            />
            <StateEntityType
                v-if="selected == 'state'"
                :payloadFinalBeneficiary="payloadFinalBeneficiary"
                :indexFinalBeneficiary="indexFinalBeneficiary"
            />
            <PropertyType
                v-if="selected == 'property'"
                :allowListedStockExchange="true"
                :payloadFinalBeneficiary="payloadFinalBeneficiary"
                :indexFinalBeneficiary="indexFinalBeneficiary"
            />
        </div>

        <div v-if="selected != 'listed'">
            <hr>
            <h6 class="page-title pb-2">Beneficiarios Finales ({{ this.getFinalBeneficiaries.length }})</h6>

            <b-tabs fill content-class="mt-3" v-model="tabIndex">
                <b-tab title="Persona Natural" v-if="selected != 'state' && selected != 'property'">
                    <CompleteFinalBeneficiaries
                        :finalBeneficiaries="getFinalBeneficiaries"
                        :finalBeneficiaryWho="0"
                        @update="notifyUpdateFinalBeneficiary"
                        @delete="deleteFinalBeneficiary"
                    />
                </b-tab>
                <b-tab title="Persona Jurídica que cotiza en la bolsa" v-if="selected != 'state' && selected != 'property'">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="getFinalBeneficiaries"
                        :finalBeneficiaryWho="1"
                        @update="notifyUpdateFinalBeneficiary"
                        @delete="deleteFinalBeneficiary"
                    />
                </b-tab>
                <b-tab title="Estado" v-if="selected != 'state'">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="getFinalBeneficiaries"
                        :finalBeneficiaryWho="2"
                        @update="notifyUpdateFinalBeneficiary"
                        @delete="deleteFinalBeneficiary"
                    />
                </b-tab>
                <b-tab title="Entidad Estatal o Multilateral" v-if="selected != 'property'">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="getFinalBeneficiaries"
                        :finalBeneficiaryWho="3"
                        @update="notifyUpdateFinalBeneficiary"
                        @delete="deleteFinalBeneficiary"
                    />
                </b-tab>
            </b-tabs>
        </div>

        <b-row align-h="end">
            <b-col cols="2" class="text-end">
                <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
                    class="d-inline-block">
                    <b-button class="my-4 main-btn" :disabled="!canSave" @click="save">Guardar Entidad</b-button>
                </b-overlay>
            </b-col>
        </b-row>
        <ConfirmationModal />
    </div>
</template>

<script>
import LegalPersonOperationType from "../legal-entities-types/LegalPersonOperationType.vue";
import LegalPersonOperationInPanamaType from "@/components/legal-entities-types/LegalPersonOperationInPanamaType.vue";
import ListedLegalType from "../legal-entities-types/ListedLegalType.vue";
import StateEntityType from "../legal-entities-types/StateEntityType.vue";
import PropertyType from "../legal-entities-types/PropertyType.vue";
import ConfirmationModal from "../legal-entities-types/modals/ConfirmationModal.vue";
import CompleteFinalBeneficiaries from "../legal-entities-types/list/CompleteFinalBeneficiaries.vue";
import SimpleFinalBeneficiaries from "../legal-entities-types/list/SimpleFinalBeneficiaries.vue";

import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "new-legal-entity",
    components: {
        LegalPersonOperationType,
        LegalPersonOperationInPanamaType,
        ListedLegalType,
        StateEntityType,
        PropertyType,
        ConfirmationModal,
        CompleteFinalBeneficiaries,
        SimpleFinalBeneficiaries
    },
    data() {
        return {
            selected: "legal-person-operation",
            options: [
                {
                    text: "Persona Jurídica con operaciones y/o activos en Panamá",
                    value: "legal-person-operation",
                    class: "p-3"
                },
                {
                    text: "Persona Jurídica con operaciones y/o activos fuera de Panamá",
                    value: "legal-person-no-operation",
                    class: "p-3"
                },
                {
                    text: "Persona jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá",
                    value: "legal-person-mix-operation",
                    class: "p-2"
                },
                {
                    text: "Persona jurídica listada en Bolsa de Valores",
                    value: "listed",
                    class: "p-3"
                },
                {
                    text: "Persona jurídica propiedad de una Entidad Estatal o Multilateral",
                    value: "state",
                    class: "p-3"
                },
                {
                    text: "Persona jurídica propiedad de un Estado",
                    value: "property",
                    class: "p-3"
                },
            ],
            sending: false,
            tabIndex: 0,
            payloadFinalBeneficiary: null,
            indexFinalBeneficiary: null
        };
    },
    mounted() {
        if (!this.allowFinalBeneficiary && !this.enabledCriteria) {
            this.$router.push({
                name: "legal-entities",
            });
        }
        this.disabledForms(true);
    },
    computed: {
        ...mapGetters("legalEntity", [
            "getLegalEntity",
            "getFinalBeneficiaries",
            "getDirectorNominees",
            "getShareholderNominees",
            "getDirectorNominee",
            "getShareholderNominee",
            "getLegalRepresentatives",
            "getSaveLegalEntity"
        ]),
        ...mapGetters("application", [
            "allowResidentAgentAdminFinalBeneficiaries",
            "getEnabledCriteria",
        ]),
        allowFinalBeneficiary() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        canSave() {
            return this.getSaveLegalEntity;
        },
        enabledCriteria() {
            return this.getEnabledCriteria;
        }
    },
    updated() {
        this.disabledForms(!this.canSave);
    },
    methods: {
        ...mapActions("legalEntity", ["reset"]),
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        setActive(e) {
            this.reset();
            this.selected = e.currentTarget.id;
        },
        disabledForms(value) {
            var form = this.$refs.formFinalBeneficiaries?.querySelector('.formfinalbeneficiary')?.querySelectorAll('input, select');
            if (form) {
                form.forEach(item => {
                    item.disabled = value;
                });
            }
        },
        async save() {
            let response = null;
            try {
                this.sending = true;
                let request = {
                    legalEntity: this.getLegalEntityFromState(),
                    finalBeneficiaries: this.getFinalBeneficiaries,
                    nomineeDirector: this.getDirectorNominee,
                    nomineeShareholder: this.getShareholderNominee,
                    nomineeDirectors: this.getDirectorNominees,
                    nomineeShareholders: this.getShareholderNominees,
                    legalRepresentatives: this.getLegalRepresentatives
                };

                if (this.getFinalBeneficiaries.length === 0 && this.selected !== "listed") {
                    this.setErrorBenefInfo();
                }
                else {
                    response = await RepositoryFactory.legalEntity.add(request);
                    this.setSuccesInfo();
                    this.reset();
                }
            } catch (error) {
                let status = error.response?.status;
                let errorResponse = error.response?.data;

                if (status === 400) {
                    if (errorResponse.code === 410) {                        
                        this.setBusinessAlreadyExistsInfo();
                    }
                    else if (errorResponse.code === 411) {
                        this.setErrorStatusPublicRecordEntityInfo();
                    }
                    else if (errorResponse.code === 412) {                        
                        this.setErrorDissolvedEntityInfo();
                    }
                    else if (errorResponse.code === 404){                        
                        this.setErrorNotFoundInfo();
                    }
                    else if (typeof errorResponse === "object") {
                        let message = "";
                        if (errorResponse?.title === "One or more validation errors occurred." && errorResponse?.errors) {
                            var keyValidation = Object.keys(errorResponse.errors)[0];
                            if (keyValidation.includes("enrollmentDate")) {
                                message = "Fecha de inscripción es requerido.";
                            } else if (keyValidation.includes("reasonGetFinalBeneficiaryStatus.status")) {
                                message = "El motivo es requerido.";
                            } else {
                                message = "Error al consumir servicio.";
                            }
                        } else {
                            errorResponse.forEach((item) => {
                                const cadena = item.property;
                                const match = cadena.match(/\[(\d+)\]/);
                                const row = match ? parseInt(match[1]) : null; 
                                let index = 0;
                                if (row != null) {
                                    let label = '';
                                    switch(this.getFinalBeneficiaries[row].finalBeneficiaryWho) {
                                        case 0:
                                            var pnItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 0);
                                            index = pnItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                            label = 'Persona Natural';
                                            break;
                                        case 1:
                                            var pbItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 1);
                                            index = pbItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                            label = 'Persona Jurídica que cotiza en bolsa';
                                            break;
                                        case 2:
                                            var eItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 2);
                                            index = eItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                            label = 'Estado';
                                            break;
                                        case 3:
                                            var enItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 3);
                                            index = enItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                            label = 'Entidad Estatal o Multilateral';
                                            break;
                                    }
                                    message += `<span>En la Fila ${index + 1} del Tipo de Beneficiario Final ${label}: </span>`;
                                }                           
                                let msg = this.$t(item.value);
                                message += `<p>${msg}</p>`;
                            });
                        }
                        this.openModal();
                        this.setModalInfo({
                            title: "Aviso",
                            message: message,
                        });
                        this.sending = false;
                        return;
                    }
                } else {
                    this.setErrorInfo();
                }
            } finally {
                this.sending = false;
            }
            
            this.openModal();

            if (response !== null && response.data) {
                await RepositoryFactory.riskLevelRepository.addFinal({ legalEntityId: response.data });
            }
        },
        setBusinessAlreadyExistsInfo() {
            this.setModalInfo({
                title: "Persona jurídica ya registrada",
                message:
                    "La persona jurídica que intenta registrar ya existe en el sistema, por favor cambie los datos y vuelva a intentarlo",
            });
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: "Entidad creada correctamente",
                message: "Se ha registrado correctamente la entidad.",
                routeName: "legal-entities",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: "Error creando la entidad",
                message:
                    "Se ha producido un error al intentar crear la entidad.",
                subMessage: "Por favor complete los datos y vuelva a intentarlo.",
            });
        },
        setErrorBenefInfo() {
            this.setModalInfo({
                title: "Error creando la entidad",
                message:
                    "Se ha producido un error al intentar crear la entidad.",
                subMessage: "Por favor debe tener al menos un beneficiario creado.",
            });
        },
        setErrorStatusPublicRecordEntityInfo() {
            this.setModalInfo({
                title: "Error creando la entidad",
                message:
                    "La sociedad que acaba de introducir no se encuentra vigente, por ende, no la puede registrar.",
                subMessage: "Por favor revise los datos y vuelva a intentarlo.",
            });
        },
        setErrorDissolvedEntityInfo() {
            this.setModalInfo({
                title: "Error creando la entidad",
                message:
                    "La sociedad que acaba de introducir se encuentra disuelta, por ende, no la puede registrar.",
                subMessage: "Por favor revise los datos y vuelva a intentarlo.",
            });
        },
        setErrorNotFoundInfo() {
            this.setModalInfo({
                title: "Error creando la entidad",
                message:
                    "El folio de la sociedad que acaba de introducir no se encuentra en el registro publico, por ende, no la puede registrar.",
                subMessage: "Por favor revise los datos y vuelva a intentarlo.",
            });
        },
        getLegalEntityFromState() {
            let legalEntity = this.getLegalEntity;
            switch (this.selected) {
                case "legal-person-no-operation":
                    legalEntity.legalEntityType = 0;
                    break;
                case "legal-person-operation":
                    legalEntity.legalEntityType = 1;
                    break;
                case "listed":
                    legalEntity.legalEntityType = 2;
                    break;
                case "state":
                    legalEntity.legalEntityType = 3;
                    break;
                case "property":
                    legalEntity.legalEntityType = 4;
                    break;
                case "legal-person-mix-operation":
                    legalEntity.legalEntityType = 5;
                    break;
            }
            return legalEntity;
        },
        tabSelection(index) {
            this.tabIndex = index;
        },
        notifyUpdateFinalBeneficiary(payload) {
            this.payloadFinalBeneficiary = payload;
        },
        deleteFinalBeneficiary(payload) {
            this.indexFinalBeneficiary = payload;
        }
    },
};
</script>

<style scoped>
.card-tab {
    padding: 3px 0;
    height: 100%;
}

.card-tab-title {
    font-size: 12px;
    font-weight: 500;
    color: #717171;
    line-height: 1.3;
}

.card-tab.active {
    background: rgb(232 243 255);
    border-color: #406892;
}

::v-deep .card-header {
    border-bottom: unset !important;
}

@media (max-width: 765px) {
    .card-option {
        flex: unset;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {

    #state,
    #private-interest-foundation,
    #maritime-society {
        padding: 11px 0;
    }

    .card-option {
        flex: unset;
        width: 33%;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .card-option {
        flex: unset;
        width: 25%;
    }

    #state,
    #private-interest-foundation,
    #maritime-society {
        padding: 11px 0;
    }
}

@media (min-width: 1400px) {

    #state,
    #private-interest-foundation,
    #maritime-society {
        padding: 11px 0;
    }
}

@media (max-width: 430px) {
    .card-tab {
        padding: 14px 0;
    }
}
</style>
