<template>
    <div class="mt-2">
        <b-form-group class="mt-3">
            <label class="form-title">Primer Nombre*</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.name" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Segundo Nombre</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.secondName" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Primer Apellido*</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.surnames" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Segundo Apellido</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.secondSurname" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">
                Número de cédula, pasaporte o documento de identidad personal
                (formato TE)*
            </label>
            <b-form-input v-model="finalBeneficiaryForm.cedula" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Fecha de nacimiento*</label>
            <DatePicker v-model="finalBeneficiaryForm.birthDate" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Nacionalidad*</label>
            <select class="form-select form-select-sm rounded-0" v-model="finalBeneficiaryForm.nationality">
                <option :value="null"></option>
                <option v-for="country in countries" :key="country" :value="country">
                    {{ country }}
                </option>
            </select>
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Dirección*</label>
            <b-form-input v-model="finalBeneficiaryForm.direction" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">
                Fecha en la que se adquiere la condición de beneficiario final
                de la persona jurídica*
            </label>
            <DatePicker v-model="finalBeneficiaryForm.statusAcquireDate" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Email</label>
            <b-form-input placeholder="xxx@xxx.com" v-model="finalBeneficiaryForm.email" size="sm" class="rounded-0" />
        </b-form-group>

        <PhoneField ref="phoneField" v-model="finalBeneficiaryForm.phone" />

        <ReasonGetFinalBeneficiaryStatusSelect ref="benefSelect"
            v-model="finalBeneficiaryForm.reasonGetFinalBeneficiaryStatus" />

        <b-button class="my-4 main-btn" @click="addFinalBeneficiary" :disabled="!canSave">
            Agregar Beneficiario Final
        </b-button>
    </div>
</template>

<script>
import { countries } from "@/config/countries";
import PhoneField from "@/components/external-components/PhoneField.vue";
import DatePicker from "@/components/widgets/DatePicker.vue";
import ReasonGetFinalBeneficiaryStatusSelect from "@/components/widgets/ReasonGetFinalBeneficiaryStatusSelect.vue";
import { ValidMail } from "../../../utils/validator";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "final-beneficiary-operation-form",
    components: {
        PhoneField,
        DatePicker,
        ReasonGetFinalBeneficiaryStatusSelect,
    },
    props: {
        payloadFinalBeneficiary: {
            type: Object,
            default: null,
        },
        indexFinalBeneficiary: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            countries: countries,
            finalBeneficiaryIndex: null,
            finalBeneficiaryForm: {
                name: "",
                surnames: "",
                secondName: "",
                secondSurname: "",
                cedula: "",
                birthDate: null,
                nationality: "",
                direction: "",
                email: "",
                phone: "",
                statusAcquireDate: null,
                reasonGetFinalBeneficiaryStatus: { others: "", status: null }
            },
            finalBeneficiaries: [],
            finalBeneficiaryWho: 0,
        };
    },
    computed: {
        ...mapGetters("legalEntity", ["getFinalBeneficiaries"]),
        canSave() {
            let form = this.finalBeneficiaryForm;
            var isEmail = form.email && form.email.trim() !== '' ? ValidMail(form.email) : true;
            let valid =
                form.name !== "" &&
                form.surnames !== "" &&
                form.cedula !== "" &&
                form.birthDate !== null &&
                form.nationality !== "" &&
                form.direction !== "" &&
                form.reasonGetFinalBeneficiaryStatus !== null &&
                form.reasonGetFinalBeneficiaryStatus.status !== null &&
                form.statusAcquireDate !== "" &&
                isEmail &&
                form.statusAcquireDate !== null;

            return valid;
        },
    },
    mounted() {
        this.finalBeneficiaries = this.getFinalBeneficiaries;
    },
    watch: {
        payloadFinalBeneficiary(payload) {
            this.notifyUpdateFinalBeneficiary(payload);
        },
        indexFinalBeneficiary(payload) {
            this.deleteFinalBeneficiary(payload.index);
        }
    },
    methods: {
        ...mapActions("legalEntity", ["setFinalBeneficiaries"]),
        addFinalBeneficiary() {
            let finalBeneficiary = { ...this.finalBeneficiaryForm };
            finalBeneficiary.finalBeneficiaryType = 1;
            finalBeneficiary.finalBeneficiaryWho = 0;

            if (this.finalBeneficiaryIndex !== null) {
                this.finalBeneficiaries.splice(
                    this.finalBeneficiaryIndex,
                    1,
                    finalBeneficiary
                );
                this.finalBeneficiaryIndex = null;
            } else {
                this.finalBeneficiaries.push(finalBeneficiary);
            }

            this.$emit("update", this.finalBeneficiaries);
            this.clearFields();
            this.setFinalBeneficiaries(this.finalBeneficiaries);
        },
        notifyUpdateFinalBeneficiary(payload) {
            this.finalBeneficiaryIndex = payload.index;
            let form = { ...payload.finalBeneficiary };

            this.finalBeneficiaryForm = {
                name: form.name,
                secondName: form.secondName,
                surnames: form.surnames,
                secondSurname: form.secondSurname,
                cedula: form.cedula,
                birthDate: form.birthDate,
                nationality: form.nationality,
                direction: form.direction,
                statusAcquireDate: form.statusAcquireDate,
                phone: form.phone,
                email: form.email,
                reasonGetFinalBeneficiaryStatus: { others: form.reasonGetFinalBeneficiaryStatus.others, status: form.reasonGetFinalBeneficiaryStatus.status }
            };
            this.setFinalBeneficiaries(this.finalBeneficiaries);
            this.$refs.phoneField.loadPhone(this.finalBeneficiaryForm.phone);
            this.$refs.benefSelect.load(form.reasonGetFinalBeneficiaryStatus.status, form.reasonGetFinalBeneficiaryStatus.others);
        },
        deleteFinalBeneficiary(index) {
            this.finalBeneficiaries.splice(index, 1);
            if (this.finalBeneficiaryIndex === index) {
                this.finalBeneficiaryIndex = null;
            }
            this.setFinalBeneficiaries(this.finalBeneficiaries);
        },
        clearFields() {
            this.finalBeneficiaryForm = {
                name: "",
                surnames: "",
                secondName: "",
                secondSurname: "",
                cedula: "",
                birthDate: null,
                nationality: "",
                direction: "",
                email: "",
                phone: "",
                statusAcquireDate: null,
                reasonGetFinalBeneficiaryStatus: { others: "", status: null }
            };
            this.$refs.phoneField.clear();
            this.$refs.benefSelect.clear();
        },
    },
};
</script>

<style scoped>
.toggle-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
</style>