<template>
    <b-form-group class="mt-3">
        <b-row>
            <b-col cols="2">
                <label class="form-title">{{
                    $t("general.phone-prefix")
                }}</label>
                <select class="form-select form-select-sm rounded-0" v-model="prefix">
                    <option :value="null"></option>
                    <option v-for="n in this.prefixes" :key="n" :value="n">
                        {{ n }}
                    </option>
                </select>
                <!-- <b-form-input id="name-input" v-model="prefix" size="sm" required class="rounded-0" disabled></b-form-input> -->
            </b-col>
            <b-col cols="10">
                <label class="form-title">{{ $t("general.phone") }}</label>
                <b-form-input placeholder="xxxxxxxx" id="name-input" v-model="phone" size="sm" required
                    class="rounded-0" number></b-form-input>
            </b-col>
        </b-row>
    </b-form-group>
</template>

<script>
import { prefixes } from '../../config/phonePrefixes';

export default {
    name: "phone-field",
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            prefix: "+507",
            phone: "",
            prefixes: [],
        };
    },
    mounted() {
        this.prefixes = prefixes.filter((entry, index, self) =>
            index === self.findIndex(t => (
                t[Object.keys(t)[0]] === entry[Object.keys(entry)[0]]
            ))
        )
            .map(entry => entry[Object.keys(entry)[0]]);
    },
    computed: {
        completePhone() {
            return `${this.prefix === undefined ? ' ' : this.prefix} ${this.phone}`.trim();
        },
    },
    watch: {
        prefix() {
            this.$emit("input", this.completePhone);
        },
        phone() {
            this.$emit("input", this.completePhone);
        },
    },
    methods: {
        loadPhone(value) {
            var slices = value.trim().split(" ");
            if (slices.length === 1) {
                this.phone = value.trim();
                this.prefix = "";
            } else if (slices.length === 2) {
                this.prefix = slices[0];
                if (!this.prefix.startsWith("+")) {
                    this.prefix = `+${this.prefix}`;
                }
                this.phone = slices[1];
            } else {
                this.phone = this.prefix = "";
            }
        },
        clear() {
            this.prefix = ""
            this.phone = "";
        },
        getPrefixes(country) {
            const countrySelected = country.toUpperCase()
            const found = prefixes.find(entry => Object.keys(entry)[0] === countrySelected);
            if (found) {
                this.prefix = found[countrySelected];
            }
        }
    },
};
</script>