<template>
    <b-modal id="certification"
             content-class="rounded-0"
             dialog-class="long-modal"
             header-class="border-0"
             header-bg-variant="light"
             hide-header-close
             centered no-close-on-backdrop hide-footer>
        <b-row class="text-center mt-2">
            <h6 class="page-title pb-2">CONSTANCIA DE REGISTRO</h6>
            <b-card border-variant="light" class="px-xl-5" header-class="border-0">
                <template #header>
                    <h6 class="mb-0 card-title-color">Informaci&oacute;n de sociedad</h6>
                </template>
                <b-card-text>
                    <b-row class="text-start">
                        <b-col>
                            <b-row class="mb-2">
                                <b-col sm="6">
                                    <label class="details-title">Agente residente</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">Nombre agente residente</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="6">
                                    <label class="details-title">Fecha de registro</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">00/00/0000</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="6">
                                    <label class="details-title">N&uacute;mero de registro de la sociedad</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">XXXX</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="6">
                                    <label class="details-title">Nombre de la sociedad</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">Nombre sociedad</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="6">
                                    <label class="details-title">Nombre de Beneficiario Final</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">Nombre beneficiario final</span>
                                </b-col>
                            </b-row>
                        </b-col>                      
                    </b-row>   
                    <b-row>
                        <div class="note">
                           <span>Esta certificaci&oacute;n debe reposar en el expediente digital o f&iacute;sico del Agente Residente</span>
                        </div>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-row>
        <div class="text-center mt-2">
          <b-button size="sm" class="main-btn px-3" @click="$bvModal.hide('certification')">Aceptar</b-button>   
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'certification'
    }
</script>

<style scoped>
    .details-title, .details-title-value {
        font-size: 13px;
    }

    .note {
        padding: 0.5rem 1rem;      
        background-color: rgba(0, 0, 0, 0.03);
        border: 0;
        font-size: 11px;
        font-weight: 500;
        color: #717171;
        margin-top: 1rem;
    }
</style>