<template>
    <b-row class="mt-3">
        <b-col lg="5">
            <LegalPersonStateForm />
        </b-col>
        <b-col lg="7">
            <b-tabs fill content-class="mt-3">
                <b-tab class="formfinalbeneficiary" title="Beneficiarios Finales" active>
                    <FinalBeneficiaryOperationForm
                        v-if="selected === 'naturalPerson'"
                        :allowListedStockExchange="allowListedStockExchange"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <BolsaFinalBeneficiaryForm
                        v-if="selected ==='pjbolsa'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <StateFinalBeneficiaryForm 
                        v-if="selected === 'state'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <ListedFinalBeneficiaryForm
                        v-if="selected === 'EEM'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
import LegalPersonStateForm from "./forms/LegalPersonStateForm.vue";
import FinalBeneficiaryOperationForm from "@/components/legal-entities-types/forms/FinalBeneficiaryOperationForm.vue";
import StateFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryStateForm.vue";
import BolsaFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryBolsaForm.vue";
import ListedFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryListedForm.vue";

export default {
    name: "state-entity-type",
    components: {
        ListedFinalBeneficiaryForm,
        BolsaFinalBeneficiaryForm,
        StateFinalBeneficiaryForm,
        FinalBeneficiaryOperationForm,
        LegalPersonStateForm,
    },
    props: {
        allowListedStockExchange: {
            type: Boolean,
            default: false,
        },
        showNoAssets: {
            type: Boolean,
            default: false,
        },
        payloadFinalBeneficiary: {
            type: Object,
            default: null,
        },
        indexFinalBeneficiary: {
            type: Object,
            default: null,
        },
        tabIndex: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            selected: 'EEM',
            options: [
                { value: 'naturalPerson', text: 'Persona Natural' },
                { value: 'pjbolsa', text: 'Persona Jurídica que cotiza en bolsa' },
                { value: 'state', text: 'Estado' },
                { value: 'EEM', text: 'Entidad Estatal o Multilateral' }
            ]
        }
    }
};
</script>