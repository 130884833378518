<template>
    <b-card border-variant="light">
        <template #header>
            <h6 class="mb-0 card-title-color">
                {{ $t("legal-person.title") }}
            </h6>
        </template>
        <b-card-text>
            <b-row class="mt-2">
                <b-col>
                    <b-form-group>
                        <label class="form-title">
                            {{ $t("general.society-type") }}
                        </label>
                        <select class="form-select form-select-sm rounded-0" v-model="legalPersonForm.societyType">
                            <option value="SA">
                                {{ $t("society-types.sociedad anonima") }}
                            </option>
                            <option value="FU">
                                {{
                                    $t(
                                        "society-types.fundacion de interes propio"
                                    )
                                }}
                            </option>
                            <option value="LI">
                                {{ $t("society-types.sociedad limitada") }}
                            </option>
                            <option value="EX">
                                {{ $t("society-types.sociedad extranjera") }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group class="mt-3">
                        <label class="form-title">
                            Número de ficha o folio*
                        </label>
                        <b-input-group>
                            <b-form-input @keyup.enter="callRegisterPublic" v-model="legalPersonForm.folioNumber" size="sm"
                                required class="rounded-0" id="tooltip-target" />
                            <b-tooltip target="tooltip-target" triggers="hover">
                                Presione <b>enter</b> para autocompletar los datos del Registro Público
                            </b-tooltip>
                            <b-input-group-append>
                                <b-overlay
                                    :show="loading"
                                    rounded
                                    opacity="0.7"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block"
                                >
                                    <b-button
                                        size="sm"
                                        class="btn main-btn"
                                        @click="callRegisterPublic"
                                        v-b-tooltip.hover
                                        title="Autocompletar los datos del Registro Público"
                                    >
                                        Verificar
                                    </b-button>
                                </b-overlay>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <b-row v-if="loading">
                        <b-col cols="1">
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle" />
                            </div>
                        </b-col>
                        <b-col cols="11">
                            <div class="text-center text-primary my-2">
                                <strong>Cargando datos del Registro Público ...</strong>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-else-if="publicRegisterStatus.message !== ''">
                        <b-col cols="1">
                            <div class="text-center text-primary my-2">
                                {{ publicRegisterStatus.error ? '❌' : '✅' }}
                            </div>
                        </b-col>
                        <b-col cols="11">
                            <div class="text-center text-primary my-2">
                                <strong>{{ publicRegisterStatus.message }}</strong>
                            </div>
                        </b-col>
                    </b-row>
                    <b-form-group class="mt-3">
                        <label class="form-title">Nombre completo*</label>
                        <b-icon-question-circle-fill class="ms-2 card-title-color" title="Como aparece en el Registro Público" />
                        <b-form-input v-model="legalPersonForm.name" size="sm" required class="rounded-0"
                            :disabled="disabled == 1" />
                    </b-form-group>
                    <b-form-group class="mt-3">
                        <label class="form-title">RUC*</label>
                        <b-form-input v-model="legalPersonForm.ruc" size="sm" required class="rounded-0" />
                    </b-form-group>
                    <b-form-group class="mt-3" v-if="disabled == 0">
                        <label class="form-title">Fecha de inscripción*</label>
                        <b-icon-question-circle-fill class="ms-2 card-title-color"
                            title="Fecha de inscripción en el Registro Público de Panamá" />
                        <DatePicker v-model="legalPersonForm.enrollmentDate" />
                    </b-form-group>
                    <b-form-group class="mt-3" v-if="disabled == 1">
                        <label class="form-title">Fecha de inscripción*</label>
                        <b-icon-question-circle-fill class="ms-2 card-title-color"
                            title="Fecha de inscripción en el Registro Público de Panamá" />
                        <b-form-input v-model="legalPersonForm.enrollmentDate" disabled />
                    </b-form-group>
                    <b-form-group class="mt-3">
                        <label class="form-title">Dirección*</label>
                        <b-form-input v-model="legalPersonForm.direction" size="sm" required class="rounded-0" />
                    </b-form-group>
                    <b-form-group class="mt-3">
                        <label class="form-title">Actividad principal*</label>
                        <b-icon-question-circle-fill class="ms-2 card-title-color"
                            title="“En caso de duda sobre la actividad económica que desarrolla la persona jurídica, consultar la Clasificación Industrial Nacional Uniforme de todas las Actividades Económicas (CINU)" />
                        <PrincipalActivitySelect v-model="legalPersonForm.principalActivity" />
                    </b-form-group>
                    <b-row>
                        <b-col cols="5">
                            <b-form-group class="mt-3">
                                <label class="form-title">
                                    Jurisdicción *
                                </label>
                                <select class="form-select form-select-sm rounded-0"
                                    v-model="jurisdictionselect">
                                    <option value=" ">

                                    </option>
                                    <option v-for="country in countries" :key="country" :value="country">
                                        {{ country }}
                                    </option>
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5"> <b-form-group class="mt-3">
                                <label class="form-title">NIT*</label>
                                <b-form-input v-model="nit" size="sm" required class="rounded-0" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-button style="margin-top: 40%" v-b-tooltip.hover title="Salvar" @click="addJuridiction"
                                class="main-btn ">
                                <b-icon-plus scale="1.5" />
                            </b-button>
                        </b-col>
                    </b-row>
                    <listJuridictions :jurisdictions="legalPersonForm.jurisdictions" @delete="deleteJuridiction" />
                    <label class="form-title">
                        Servicio de accionistas / directores nominales o apoderados
                    </label>
                    <b-td style="border: 1px"></b-td>
                    <b-row>
                        <b-col cols="9">
                            <b-form-group>
                                <label class="form-title">
                                    ¿Servicio de accionista Nominal?
                                </label>
                            </b-form-group>
                            <b-form-group>
                                <label class="form-title">
                                    ¿Servicio de director Nominal?
                                </label>
                            </b-form-group>
                            <b-form-group>
                                <label class="form-title">
                                    ¿Servicio de apoderado?
                                </label>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                            :aria-describedby="ariaDescribedby" name="radio1" :value="true"> Si</b-form-radio>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                            :aria-describedby="ariaDescribedby" name="radio1" :value="false"> No</b-form-radio>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                            :aria-describedby="ariaDescribedby" name="radio2" :value="true"> Si</b-form-radio>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                            :aria-describedby="ariaDescribedby" name="radio2" :value="false"> No</b-form-radio>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.proxyService"
                                            :aria-describedby="ariaDescribedby" name="radio3" :value="true"> Si</b-form-radio>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-radio v-model="legalPersonForm.proxyService"
                                            :aria-describedby="ariaDescribedby" name="radio3" :value="false"> No</b-form-radio>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</template>

<script>
import { countries } from "@/config/countries";
import { mapActions } from "vuex";

import DatePicker from "@/components/widgets/DatePicker.vue";
import PrincipalActivitySelect from "@/components/widgets/PrincipalActivitySelect.vue";
import listJuridictions from "@/components/legal-entities-types/list/ListJuridictions.vue";
import { checkPublicRegister } from "../../../services/integration";
import { parsePublicRegisterError } from "../../../utils/parse";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "legal-person-operation-form",
    components: {
        listJuridictions,
        DatePicker,
        PrincipalActivitySelect,
    },
    props: {
        showNoAssets: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            countries: countries,
            legalPersonForm: {
                name: null,
                ruc: null,
                folioNumber: null,
                enrollmentDate: null,
                direction: null,
                principalActivity: null,
                societyType: "SA",
                shareholder: false,
                noAssets: false,
                nominalShareholderService: false,
                nominalDirectorService: false,
                proxyService: false,
                jurisdictions: []
            },
            disabled: 0,
            loading: false,
            publicRegisterStatus: { error: false, message: '' },
            jurisdictionselect: null,
            nit: null,
            juridictionIndex: null
        };
    },
    watch: {
        legalPersonForm: {
            deep: true,
            handler() {
                this.saveLegalEntity();
            },
        },
    },
    methods: {
        ...mapActions("legalEntity", ["setLegalEntity", "setSaveLegalEntity"]),
        saveLegalEntity() {
            this.setLegalEntity(this.legalPersonForm);
        },
        changeShareholder() {
            if (this.showNoAssets && this.legalPersonForm.shareholder) {
                this.legalPersonForm.noAssets = false;
            }
        },
        changeAssets() {
            if (this.showNoAssets && this.legalPersonForm.noAssets) {
                this.legalPersonForm.shareholder = false;
            }
        },
        addJuridiction() {
            if ((typeof this.jurisdictionselect != "undefined" && this.jurisdictionselect) && (typeof this.nit != "undefined" && this.nit)) {
                let juridictObj = { jurisdiction: this.jurisdictionselect, nit: this.nit };
                this.legalPersonForm.jurisdictions.push(juridictObj);
                this.jurisdictionselect = " ";
                this.nit = null;
            }
        },
        deleteJuridiction(index) {
            this.legalPersonForm.jurisdictions.splice(index, 1);
            if (this.juridictionIndex === index) {
                this.juridictionIndex = null;
            }
        },
        async callRegisterPublic() {
            this.cleanForm()
            if (this.legalPersonForm.folioNumber === '')
                return;

            this.loading = true;

            let response = await RepositoryFactory.legalEntity.any({
                folio : this.legalPersonForm.folioNumber,
                societyType: this.legalPersonForm.societyType,
                enabled: true
            });

            if (response && response?.status === 200 && response?.data) {
                this.disabled = 1;
                this.loading = false;
                this.publicRegisterStatus = { error: true, message: parsePublicRegisterError(501) };
                return;
            }

            const result = await checkPublicRegister(this.legalPersonForm.folioNumber, this.legalPersonForm.societyType);

            if (result.code === 200) {
                const stringDate = result.data.fechaDeRegistro;
                this.legalPersonForm.name = result.data.nombre;
                this.legalPersonForm.direction = result.data.domicilio;
                this.legalPersonForm.enrollmentDate = stringDate.substring(4, 8) + "-" + stringDate.substring(2, 4) + "-" + stringDate.substring(0, 2);
                this.legalPersonForm.ruc = result.data.ruc;
                this.disabled = 1;
                this.loading = false;
                this.setSaveLegalEntity(true);
                return;
            }

            this.loading = false;
            this.publicRegisterStatus = { error: true, message: parsePublicRegisterError(result.code, result.data) };
        },
        cleanForm() {
            this.setSaveLegalEntity(false);
            this.publicRegisterStatus = { error: false, message: '' }
            this.legalPersonForm.name = "";
            this.legalPersonForm.direction = "";
            this.legalPersonForm.enrollmentDate = "";
            this.legalPersonForm.ruc = "";
            this.disabled = 0;
        }
    },
};
</script>