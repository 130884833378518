<template>
    <div>
        <b-modal id="confirmation"
                 content-class="rounded-0"
                 dialog-class="long-modal"
                 header-class="border-0"
                 header-bg-variant="light"
                 hide-header-close
                 centered no-close-on-backdrop hide-footer>
            <div class="text-center">
                <p class="form-title mt-3">Se ha registrado satisfactoriamente el Beneficiario Final de la sociedad.</p>
                <p class="form-title">&#191;Desea emitir certificaci&oacute;n de registro?</p>
                <p class="form-title">Esta constancia, podrá ser emitida en cualquier momento.</p>
            </div>
            <b-row class="mt-4 mb-3">
                <b-col offset-xl="2">
                    <b-button size="sm" class="main-btn px-3 ms-4" @click="openCertificationModal">Si <b-icon-check-square class="ms-1"></b-icon-check-square></b-button>
                </b-col>
                <b-col>
                    <b-button size="sm" class="main-btn px-3 ms-4" @click="$bvModal.hide('confirmation')">No <b-icon-x-square class="ms-1"></b-icon-x-square></b-button>
                </b-col>
            </b-row>
        </b-modal>
        <CertificationModal />
    </div>   
</template>

<script>
    import CertificationModal from './CertificationModal.vue'
    export default {
        name: 'confirmation',
        components: {         
            CertificationModal
        },
        methods: {
            openCertificationModal() {
                this.$bvModal.show('certification');
                this.$bvModal.hide('confirmation');
            }
        }
    }
</script>