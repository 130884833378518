<template>
    <b-row class="mt-3">
        <b-col lg="5">
            <LegalPersonForm />
        </b-col>
        <b-col lg="7">
            <b-tabs fill content-class="mt-3">
                <b-tab class="formfinalbeneficiary" title="Beneficiarios Finales" active>
                    <label>No se requiere los datos del Beneficiario Final por la
                        “EXCEPCIÓN CONTEMPLADA EN EL NUMERAL 3.1 DEL
                        ARTICULO 10 DE LA LEY 129 de 17 marzo de 2020”</label>
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
import LegalPersonForm from "./forms/LegalPersonListedForm.vue";

export default {
    name: "offshore-type",
    components: {
        LegalPersonForm,
    },
};
</script>
