<template>
    <div>
        <p class="page-title" style="font-size: small"><strong style="color: red">**IMPORTANTE:**</strong> Para editar un Beneficiario Final de la tabla debe seleccionar el tipo de Beneficiario Final que va a editar y darle clic al botón verde con el icono del lápiz. El botón se habilita una vez le da clic al tipo de Beneficiario Final correcto.</p>
        <b-table
            striped
            hover
            :items="items"
            :fields="columns"
            show-empty
            responsive
        >
            <template #empty>
                <h6 class="text-center">No hay beneficiarios finales</h6>
            </template>
            <template #cell(actions)="data">
                <div>
                    <b-button @click="editRow(data.index)" variant="success">
                        <b-icon icon="pencil" v-b-tooltip.hover title="Editar solo funciona para BF del mismo tipo" />
                    </b-button>
                    <b-button @click="removeRow(data.index)" variant="danger">
                        <b-icon icon="x-circle" />
                    </b-button>
                </div>
            </template>
            <template #cell(constitutionDate)="data">
                {{ parseDate(data.item.constitutionDate) }}
            </template>
            <template #cell(acquisitionDate)="data">
                {{ parseDate(data.item.acquisitionDate) }}
            </template>
            <template #cell(reason)="data">
                <b-icon-card-list
                    style="cursor: pointer"
                    v-b-tooltip.hover.left
                    variant="primary"
                    :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
                />
            </template>
        </b-table>
    </div>
</template>

<script>
import moment from 'moment';
import { reasons } from "@/config/final-beneficiary-reasons";

export default {
    name: "simple-final-beneficiaries",
    props: {
        finalBeneficiaries: {
            type: Array,
            required: true,
        },
        finalBeneficiaryWho: {
            type: Number,
            required: true
        }
    },
    mounted() {
        this.setWho();
    },
    data() {
        return {
            columns: [
                { key: "entityCompleteName", label: "Nombre" },
                { key: "direction", label: "Dirección" },
                { key: "country", label: "País" },
                {
                    key: "legalRepresentantCompleteName",
                    label: "Representante legal",
                },
                { key: "reason", label: "Motivo", class: "text-center" },
                { key: "actions", label: "", tdClass: "actions-column" },
            ],
            items: [],
        };
    },
    watch: {
        finalBeneficiaries() {
            this.items = this.finalBeneficiaries.filter(item => item.finalBeneficiaryWho === this.finalBeneficiaryWho);
        },
        finalBeneficiaryWho() {
            this.setWho();
        }
    },
    methods: {
        editRow(i) {
            let index = this.finalBeneficiaries.findIndex(x => x === this.items[i]);
            let finalBeneficiary = this.finalBeneficiaries[index];
            this.$emit("update", { index, finalBeneficiary });
        },
        removeRow(i) {
            let index = this.finalBeneficiaries.findIndex(x => x === this.items[i]);
            const uniqueIndex = Date.now();
            this.$emit("delete", { index, uniqueIndex });
        },
        parseDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        },
        setWho() {
            if (this.finalBeneficiaryWho === 1) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "stockExchangeCompleteName", label: "Bolsa" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "jurisdictionStockExchangeWhereListed", label: "Jurisdicción" },
                    { key: "legalRepresentantCompleteName", label: "Representante" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "actions", label: "", tdClass: "actions-column" },
                ];
            }
            if (this.finalBeneficiaryWho === 2) {
                this.columns = [
                    { key: "country", label: "País" },
                    { key: "constitutionDate", label: "Fecha de constitución" },
                    { key: "acquisitionDate", label: "Fecha de adquisición" },
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "actions", label: "", tdClass: "actions-column" },
                ];
            }
            if (this.finalBeneficiaryWho === 3) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "legalRepresentantCompleteName", label: "Representante legal" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "actions", label: "", tdClass: "actions-column" },
                ];
            }
        }
    },
};
</script>