<template>
    <div class="mt-2">
        <b-form-group>
            <label class="form-title">Nombre completo de la entidad*</label>
            <b-form-input v-model="finalBeneficiaryForm.entityCompleteName" size="sm" required class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Dirección*</label>
            <b-form-input v-model="finalBeneficiaryForm.direction" size="sm" required class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">País y/o sede*</label>
            <select class="form-select form-select-sm rounded-0" v-model="finalBeneficiaryForm.country">
                <option v-for="country in countries" :key="country" :value="country">
                    {{ country }}
                </option>
            </select>
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">
                Nombre completo del representante legal o su equivalente*
            </label>
            <b-form-input v-model="finalBeneficiaryForm.legalRepresentantCompleteName" size="sm" required
                class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Email</label>
            <b-form-input placeholder="xxx@xxx.com" v-model="finalBeneficiaryForm.email" size="sm" class="rounded-0" />
        </b-form-group>

        <PhoneField ref="phoneField" v-model="finalBeneficiaryForm.phone" />
        <ReasonGetFinalBeneficiaryStatusSelect ref="benefSelect"
            v-model="finalBeneficiaryForm.reasonGetFinalBeneficiaryStatus" />

        <b-button class="my-4 main-btn" @click="addFinalBeneficiary" :disabled="!canSave">
            Agregar Beneficiario Final
        </b-button>
    </div>
</template>

<script>
import PhoneField from "@/components/external-components/PhoneField.vue";
import { countries } from "@/config/countries";
import ReasonGetFinalBeneficiaryStatusSelect from "@/components/widgets/ReasonGetFinalBeneficiaryStatusSelect.vue";
import { ValidMail } from "../../../utils/validator";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "listed-final-beneficiary-form",
    components: {
        ReasonGetFinalBeneficiaryStatusSelect,
        PhoneField,
    },
    props: {
        payloadFinalBeneficiary: {
            type: Object,
            default: null,
        },
        indexFinalBeneficiary: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            countries: countries,
            finalBeneficiaryIndex: null,
            finalBeneficiaryForm: {
                entityCompleteName: "",
                direction: "",
                country: "",
                legalRepresentantCompleteName: "",
                phone: "",
                email: "",
                reasonGetFinalBeneficiaryStatus: { others: "", status: null }
            },
            finalBeneficiaries: [],
            finalBeneficiaryWho: 3,
        };
    },
    computed: {
        ...mapGetters("legalEntity", ["getFinalBeneficiaries"]),
        canSave() {
            let form = this.finalBeneficiaryForm;
            var isEmail = form.email && form.email.trim() !== '' ? ValidMail(form.email) : true;
            let valid =
                form.entityCompleteName !== "" &&
                form.direction !== "" &&
                form.country !== "" &&
                form.reasonGetFinalBeneficiaryStatus !== null &&
                form.reasonGetFinalBeneficiaryStatus.status !== null &&
                isEmail &&
                form.legalRepresentantCompleteName !== "";

            return valid;
        },
    },
    mounted() {
        this.finalBeneficiaries = this.getFinalBeneficiaries;
    },
    watch: {
        payloadFinalBeneficiary(payload) {
            this.notifyUpdateFinalBeneficiary(payload);
        },
        indexFinalBeneficiary(payload) {
            this.deleteFinalBeneficiary(payload.index);
        }
    },
    methods: {
        ...mapActions("legalEntity", ["setFinalBeneficiaries"]),
        addFinalBeneficiary() {
            let finalBeneficiary = { ...this.finalBeneficiaryForm };
            finalBeneficiary.finalBeneficiaryType = 0;
            finalBeneficiary.finalBeneficiaryWho = 3;

            if (this.finalBeneficiaryIndex !== null) {
                this.finalBeneficiaries.splice(
                    this.finalBeneficiaryIndex,
                    1,
                    finalBeneficiary
                );
                this.finalBeneficiaryIndex = null;
            } else {
                this.finalBeneficiaries.push(finalBeneficiary);
            }
    
            this.setFinalBeneficiaries(this.finalBeneficiaries);
            this.$emit("update", this.finalBeneficiaries);
            this.clearFields();
        },
        notifyUpdateFinalBeneficiary(payload) {
            this.finalBeneficiaryIndex = payload.index;
            let form = { ...payload.finalBeneficiary };
            this.finalBeneficiaryForm = {
                entityCompleteName: form.entityCompleteName,
                direction: form.direction,
                country: form.country,
                legalRepresentantCompleteName: form.legalRepresentantCompleteName,
                phone: form.phone,
                email: form.email,
                reasonGetFinalBeneficiaryStatus: { others: form.reasonGetFinalBeneficiaryStatus.others, status: form.reasonGetFinalBeneficiaryStatus.status }
            };
            this.setFinalBeneficiaries(this.finalBeneficiaries);
            this.$refs.phoneField.loadPhone(this.finalBeneficiaryForm.phone);
            this.$refs.benefSelect.load(form.reasonGetFinalBeneficiaryStatus.status, form.reasonGetFinalBeneficiaryStatus.others);
        },
        deleteFinalBeneficiary(index) {
            this.finalBeneficiaries.splice(index, 1);
            if (this.finalBeneficiaryIndex === index) {
                this.finalBeneficiaryIndex = null;
            }
            this.setFinalBeneficiaries(this.finalBeneficiaries);
        },
        clearFields() {
            this.finalBeneficiaryForm = {
                entityCompleteName: "",
                direction: "",
                country: "",
                legalRepresentantCompleteName: "",
                phone: "",
                email: "",
                reasonGetFinalBeneficiaryStatus: { others: "", status: null }
            };
            this.$refs.phoneField.clear();
            this.$refs.benefSelect.clear();
        },
    },
};
</script>