<template>
    <b-row class="mt-3">
        <b-col lg="5">
            <legalPersonOperationInPanamaForm :showNoAssets="showNoAssets" />
        </b-col>
        <b-col lg="7">
            <b-tabs fill content-class="mt-3">
                <b-tab class="formfinalbeneficiary" title="Beneficiarios Finales" active>
                    <b-form-group class="mt-3">
                        <label class="form-title">Tipo de Beneficiario Final</label>
                    </b-form-group>
                    <b-form-select
                        class="form-select form-select-sm rounded-0"
                        v-model="selected"
                        :options="options"
                        @change="tabSelection">
                    </b-form-select>

                    <FinalBeneficiaryOperationForm
                        v-if="selected === 'naturalPerson'"
                        :allowListedStockExchange="allowListedStockExchange"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <BolsaFinalBeneficiaryForm
                        v-if="selected ==='pjbolsa'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <StateFinalBeneficiaryForm 
                        v-if="selected === 'state'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                    <ListedFinalBeneficiaryForm
                        v-if="selected === 'EEM'"
                        :payloadFinalBeneficiary="payloadFinalBeneficiary"
                        :indexFinalBeneficiary="indexFinalBeneficiary"
                    />
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>

import legalPersonOperationInPanamaForm from "@/components/legal-entities-types/forms/LegalPersonOperationInPanamaForm.vue";
import BolsaFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryBolsaForm.vue";
import StateFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryStateForm.vue";
import ListedFinalBeneficiaryForm from "@/components/legal-entities-types/forms/FinalBeneficiaryListedForm.vue";
import FinalBeneficiaryOperationForm from "./forms/FinalBeneficiaryOperationForm.vue";

export default {
    name: "legal-person-operation-in-panama-type",
    components: {
        legalPersonOperationInPanamaForm,
        BolsaFinalBeneficiaryForm,
        StateFinalBeneficiaryForm,
        ListedFinalBeneficiaryForm,
        FinalBeneficiaryOperationForm,
    },
    props: {
        allowListedStockExchange: {
            type: Boolean,
            default: false,
        },
        showNoAssets: {
            type: Boolean,
            default: false,
        },
        payloadFinalBeneficiary: {
            type: Object,
            default: null,
        },
        indexFinalBeneficiary: {
            type: Object,
            default: null,
        },
        tabIndex: {
            type: Number,
            default: null,
        },
    },
    watch: {
        tabIndex(index) {
            switch (index) {
                case 0:
                    this.selected = 'naturalPerson';
                    break;
                case 1:
                    this.selected = 'pjbolsa';
                    break;
                case 2:
                    this.selected = 'state';
                    break;
                case 3:
                    this.selected = 'EEM';
                    break;
                default:
                    break;
            }
        }
    },
    data() {
        return {
            selected: 'naturalPerson',
            options: [
                { value: 'naturalPerson', text: 'Persona Natural' },
                { value: 'pjbolsa', text: 'Persona Jurídica que cotiza en bolsa' },
                { value: 'state', text: 'Estado' },
                { value: 'EEM', text: 'Entidad Estatal o Multilateral' }
            ]
        }
    },
    methods: {
        tabSelection(selected) {
            let index = null;

            switch (selected) {
                case 'naturalPerson':
                    index = 0;
                    break;
                case 'pjbolsa':
                    index = 1;
                    break;
                case 'state':
                    index = 2;
                    break;
                case 'EEM':
                    index = 3;
                    break;
                default:
                    break;
            }

            if (index != null) {
                this.$emit("tabSelection", index);
            }
        }
    }
};
</script>